import React from "react";

const FheaderIntro = () => {
  return (
    <>
      <div className="fheader-center page-width">
        <h2>BUY AND SELL DIGITAL ART</h2>
        <br />
        <p>
          Explore tons of digital arts, buy, own and sell arts easily on
          Mintlogixart
        </p>
      </div>
    </>
  );
};

export default FheaderIntro;
