import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

const Payments = () => {
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [screenshots, setScreenshots] = useState([]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://api.mintlogixart.com/api/verify").then((res) => {
      if (res.data.valid === true) {
        setEmail(res.data.email);
      } else {
        navigate("/login");
      }

      axios.get("https://api.mintlogixart.com/api/user/payment").then((res) => {
        setScreenshots(res.data);
      });
    });
  }, []);

  useEffect(() => {
    axios.get(`https://api.mintlogixart.com/api/users/${email}`).then((res) => {
      setUser(res.data);
    });
  }, [email]);

  return (
    <div>
      <Sidebar user={user} active={"payment"} />
      <div className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header user={user} />
        <div class="container-fluid py-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <h6>Recent Payments</h6>
                </div>
                <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            screenshots
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Sender
                          </th>
                          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Date
                          </th>
                          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {screenshots.map((payment) => (
                          <tr key={payment.id}>
                            <td>
                              <div class="d-flex px-2 py-1">
                                <div>
                                  <img
                                    src={payment?.image_url}
                                    class="avatar avatar-sm me-3"
                                    alt="user6"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <p class="text-xs text-secondary mb-0">
                                {payment?.owner}
                              </p>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <p class="text-xs text-secondary mb-0">
                                {payment?.created_at}
                              </p>
                            </td>
                            <td class="align-middle text-center">
                              <span class="text-secondary text-xs font-weight-bold">
                                <a
                                  style={{ color: "blue" }}
                                  target="blank"
                                  href={`/uploads/${payment.image_url}`}
                                >
                                  View
                                </a>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
