import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploadThumbnail from "./ImageUploadThumbnail";
import axios from "axios";
import { toast, Toaster } from "sonner";

const initialForm = {
  name: "",
  description: "",
  price: 0,
  image_url: "",
};
const EditArtwork = ({ user }) => {
  const [form, setForm] = useState(initialForm);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      const res = await axios.get(
        `https://api.mintlogixart.com/api/user/art/${id}`
      );
      setForm(res.data[0]);
    };
    fetchProduct();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.defaults.withCredentials = true;
    axios
      .put(`https://api.mintlogixart.com/api/artwork/${id}`, form)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            duration: 2000,
            position: "top-right",
            onDismiss: (t) => console.log(navigate("/dashboard")),
            onAutoClose: (t) => console.log(navigate("/dashboard")),
          });
        } else {
          toast(res.data.message);
        }
      });
  };

  return (
    <div className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Header user={user} />
      <Toaster />
      <div className="container-fluid py-4">
        <div class="card h-100">
          <div class="card-body p-3">
            <div class="card-body" style={{ padding: "10px" }}>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <h6 class="mb-3">Edit Your Artwork</h6>
                  <div class="form-group">
                    <label
                      style={{ color: "black" }}
                      for="exampleFormControlSelect1"
                    >
                      Artwork Name
                    </label>
                    <input
                      disabled
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                      value={form?.name}
                      type="text"
                      placeholder="Lorem Ipsum"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    />
                  </div>
                  <div class="form-group">
                    <label
                      style={{ color: "black" }}
                      for="exampleFormControlSelect1"
                    >
                      Artwork Price
                    </label>
                    <input
                      onChange={(e) =>
                        setForm({ ...form, price: e.target.value })
                      }
                      type="number"
                      value={form?.price}
                      name="price"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    />
                  </div>

                  <div class="form-group">
                    <label
                      style={{ color: "black" }}
                      for="exampleFormControlSelect1"
                    >
                      Artwork Owner
                    </label>

                    <input
                      disabled
                      name="owner"
                      type="text"
                      value={user?.users_name}
                      placeholder="Lorem Ipsum"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    />
                  </div>

                  <div class="form-group">
                    <label
                      style={{ color: "black" }}
                      for="exampleFormControlSelect1"
                    >
                      Artwork description
                    </label>
                    <textarea
                      disabled
                      name="description"
                      style={{ height: "200px" }}
                      onChange={(e) =>
                        setForm({ ...form, description: e.target.value })
                      }
                      type="text"
                      value={form?.description}
                      placeholder="Lorem Ipsum"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    ></textarea>
                  </div>

                  <div class="text-center">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      class="btn bg-gradient-info w-100 mt-4 mb-0"
                    >
                      Update Art
                    </button>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <span>uploads needs to be high resolution</span>
                  <div className="thumb">
                    <img
                      alt=""
                      src={`/uploads/${form?.image_url}`}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditArtwork;
