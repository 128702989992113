import React from "react";
import ArtworkIcon from "./ArtworkIcon";

const Fheader = ({ user }) => {
  return (
    <div className="fheader">
      <div className="page-width">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="logo">
            <a href="/">MINTLOGIXART</a>
          </div>
          <div className="navs">
            <a href="/login">
              <button className="btn sign-in">Login</button>
            </a>
            <a href="/register">
              <button className="btn  sign-up">Sign up</button>
            </a>
            <a href="/dashboard">
              <button className="btn art">
                <ArtworkIcon />
                <br />
                Artworks
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fheader;
