import React from "react";
import Fheader from "../components/Fheader";
import { ArtInformation } from "../components/ArtInformation";
import FFooter from "../components/FFooter";

const ArtDetails = () => {
  return (
    <div>
      <Fheader />
      <ArtInformation />
      <FFooter />
    </div>
  );
};

export default ArtDetails;
