import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Profile from "../assets/profile.png";

const initialForm = {
  balance: "",
  isAdmin: "",
};

const EditUserSection = ({ user, activeUser }) => {
  const [form, setForm] = useState(initialForm);
  const { balance, isAdmin } = form;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get(`https://api.mintlogixart.com/api/user/${id}`).then((res) => {
      setForm(res.data);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.defaults.withCredentials = true;
    axios
      .put(`https://api.mintlogixart.com/api/users-update/${id}`, form)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            duration: 2000,
            position: "top-right",
            onDismiss: (t) => console.log(navigate("/users")),
            onAutoClose: (t) => console.log(navigate("/users")),
          });
        } else {
          toast(res.data.message);
        }
      });
    console.log(form);
  };

  return (
    <div className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <Header user={activeUser} />
      <br />
      <br />
      <br />
      <Toaster />
      <div class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                src={Profile}
                alt="profile_image"
                class="w-100 border-radius-lg shadow-sm"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{user.users_name}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-4">
        <div class="card h-100">
          <div class="card-body p-3">
            <div class="card-body">
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  <form role="form">
                    <h6 class="mb-3">Edit Users Details</h6>

                    <label style={{ color: "#020202" }}>User Balance</label>
                    <div class="mb-3">
                      <input
                        onChange={(e) =>
                          setForm({ ...form, balance: e.target.value })
                        }
                        value={balance}
                        type="number"
                        class="form-control"
                        placeholder={user.balance}
                      />
                    </div>

                    <div class="form-group">
                      <label
                        for="exampleFormControlSelect1"
                        style={{ color: "#020202" }}
                      >
                        Make Admin
                      </label>
                      <select
                        onChange={(e) =>
                          setForm({ ...form, isAdmin: e.target.value })
                        }
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        {/* if is admin is tru  */}
                        {isAdmin === "true" ? (
                          <>
                            <option selected value="true">
                              True
                            </option>
                            <option value="false">False</option>
                          </>
                        ) : (
                          <>
                            <option value="true">True</option>
                            <option selected value="false">
                              False
                            </option>
                          </>
                        )}
                      </select>
                    </div>

                    <div class="text-center">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        class="btn bg-gradient-info w-100 mt-4 mb-0"
                      >
                        Save Details
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div class="mb-3">
                    <label style={{ color: "#020202" }}>User Name</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder={user.users_name}
                    />
                  </div>
                  <div class="mb-3">
                    <label style={{ color: "#020202" }}>User email</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder={user.email}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserSection;
