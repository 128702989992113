import React, { useEffect, useState } from "react";
import Header from "./Header";
import FeaturedArts from "./FeaturedArts";
import axios from "axios";

const Main = ({ user }) => {
  const [myArts, setMyArts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  setTimeout(alertFunc, 5000);
  function alertFunc() {
    axios
      .get(`https://api.mintlogixart.com/api/user/myart/${user.id}`)
      .then((res) => {
        setMyArts(res.data);
        if (res.data.length < 0) {
          setMessage("No item uploaded");
        }
        setLoading(false);
      });
  }
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  // console.log(myArts);
  return (
    <div className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Header user={user} />

      <div class="container-fluid py-4 page-width">
        <div class="fheader-center ">
          <h3 style={{ textAlign: "left" }}>Welcome to Art Marketplace</h3>
          <p style={{ textAlign: "left" }}>
            this is your personal dashboard, your arts are listed below
          </p>

          <div className="account-data">
            <div className="row">
              <div className="col-md-3 col-sm-6 m10">
                <div class="card">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i
                            class="ni ni-money-coins text-lg opacity-10"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="numbers">
                          <p
                            style={{ color: "black" }}
                            class="text-sm mb-0 text-capitalize font-weight-bold"
                          >
                            Wallet balance
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            {USDollar.format(user.balance)} Eth
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 m10">
                <a href="/deposit">
                  <div class="card">
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-4 text-end">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                            <i
                              class="ni ni-fat-add text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="numbers">
                            <p
                              style={{ color: "black" }}
                              class="text-sm mb-0 text-capitalize font-weight-bold"
                            >
                              Fund wallet
                            </p>
                            <h5 class="font-weight-bolder mb-0">Add Money</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-3 col-sm-6 m10">
                <a href="/create-art">
                  <div class="card">
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-4 text-end">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                            <i
                              class="ni ni-palette text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="numbers">
                            <p
                              style={{ color: "black" }}
                              class="text-sm mb-0 text-capitalize font-weight-bold"
                            >
                              Create Artwork
                            </p>
                            <h5 class="font-weight-bolder mb-0">Upload</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-3 col-sm-6 m10">
                <a href="/">
                  <div class="card">
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-4 text-end">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                            <i
                              class="ni ni-shop text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="numbers">
                            <p
                              style={{ color: "black" }}
                              class="text-sm mb-0 text-capitalize font-weight-bold"
                            >
                              Explore
                            </p>
                            <h5 class="font-weight-bolder mb-0">Market</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        {loading ? (
          <button class="btn btn-primary btn-sm mb-2" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : (
          <div className="row">
            {myArts?.map((item) => (
              <div class="card col-4 z-index-2">
                <div class="card-body p-3">
                  <div class="border-radius-lg py-3 pe-1">
                    <div class="chart">
                      <a href={`/art-edit/${item.id}`}>
                        <img
                          style={{ width: "100%" }}
                          src={`../uploads/${item.image_url}`}
                          alt={item.name}
                        />
                      </a>
                    </div>
                  </div>
                  <h6 class="mb-0"> {item.name}</h6>
                  <h6 class="mb-0">{USDollar.format(item.price)}</h6>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
