import React from "react";
import Profile from "../assets/profile.png";

const Sidebar = ({ active, user }) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  console.log(user?.isAdmin);
  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3"
      id="sidenav-main"
    >
      <div className="sidenav-header" style={{ textAlign: "center" }}></div>
      <hr className="horizontal dark mt-0" />
      <div
        className="collapse navbar-collapse w-auto"
        id="sidenav-collapse-main"
      >
        <div
          style={{
            textAlign: "center",

            border: "solid 1px green",
            margin: "10px",
            padding: "10px",
          }}
        >
          <img style={{ width: "70px" }} src={Profile} />
          <p>{user?.users_name}</p>
          <span>{user?.email}</span>

          <div className="balance">
            <p className="balance">
              Wallet:{" "}
              <span style={{ color: "#05ff05", fontWeight: "900" }}>
                {USDollar.format(user.balance)}
              </span>{" "}
              ETH
            </p>
          </div>
        </div>

        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className={active === "home" ? "active nav-link" : "nav-link"}
              href="/dashboard"
            >
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </a>
          </li>
          {user?.isAdmin === "true" ? (
            <>
              <li className="nav-item">
                <a
                  className={
                    active === "users" ? "active nav-link" : "nav-link"
                  }
                  href="/users"
                >
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></div>
                  <span className="nav-link-text ms-1">Users</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    active === "payment" ? "active nav-link" : "nav-link"
                  }
                  href="/payment"
                >
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></div>
                  <span className="nav-link-text ms-1">Payments</span>
                </a>
              </li>
            </>
          ) : (
            ""
          )}

          <li className="nav-item">
            <a
              className={active === "create" ? "active nav-link" : "nav-link"}
              href="/create-art"
            >
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></div>
              <span className="nav-link-text ms-1">Create Art Listing</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="/">
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></div>
              <span className="nav-link-text ms-1">Marketplace</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={active === "fund" ? "active nav-link" : "nav-link"}
              href="/deposit"
            >
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></div>
              <span className="nav-link-text ms-1">Fund Account</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
