import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import FundUser from "../components/FundUser";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Fund = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://api.mintlogixart.com/api/verify").then((res) => {
      if (res.data.valid === true) {
        setEmail(res.data.email);
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`https://api.mintlogixart.com/api/users/${email}`).then((res) => {
      setUser(res.data);
    });
  }, [email]);
  return (
    <div>
      <Sidebar user={user} active={"fund"} />
      <FundUser user={user} />
    </div>
  );
};

export default Fund;
