import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Users from "./pages/Users";
import EditUser from "./pages/EditUser";
import ArtDetails from "./pages/ArtDetails";
// import "./assets/css/nucleo-icons.css";
import "./assets/css/soft-ui-dashboard.min.css";
import "./assets/css/soft-ui-dashboard.css";
import Home from "./pages/Home";
import CreateArt from "./pages/CreateArt";
import Profile from "./pages/Profile";
import Fund from "./pages/Fund";
import EditArt from "./pages/EditArt";
import Payments from "./pages/Payments";

const App = () => {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/users" element={<Users />} />
      <Route path="/" element={<Home />} />
      <Route path="/users/:id" element={<EditUser />} />
      <Route path="/art-details/:id" element={<ArtDetails />} />
      <Route path="/art-edit/:id" element={<EditArt />} />
      <Route path="/create-art" element={<CreateArt />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/deposit" element={<Fund />} />
      <Route path="/payment" element={<Payments />} />
    </Routes>
  );
};

export default App;
