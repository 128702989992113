import React from "react";

const Header = ({ user }) => {
  console.log();
  // open and closs menu
  const showMenu = (e) => {
    console.log("hi");
    document.getElementById("sidenav-main").classList.toggle("showMenu");
  };
  return (
    <nav
      class="navbar navbar-main navbar-expand-lg px-0  shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >
      <div class="container-fluid ">
        <div
          class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          id="navbar"
        >
          <div className="fheader">
            <div className="logo">
              <a href="/">MINTLOGIXART</a>
            </div>
          </div>
          <div class="ms-md-auto pe-md-3 d-flex align-items-center no-mobile">
            <div class="input-group">
              <span class="input-group-text text-body">
                <i class="fas fa-search" aria-hidden="true"></i>
              </span>
              <input
                disabled
                type="text"
                class="form-control"
                placeholder="Type here..."
              />
            </div>
          </div>
          <ul class="navbar-nav justify-content-end">
            <li class="no-mobile nav-item d-flex align-items-center no-mobile">
              <a class="btn btn-outline-primary btn-sm mb-0 me-3" href="/">
                Logout
              </a>
            </li>
            <li class="nav-item d-flex align-items-center no-mobile">
              <a
                href="javascript:;"
                class="nav-link text-body font-weight-bold px-0"
              >
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none">{user.email}</span>
              </a>
            </li>

            <li class="nav-item" onClick={showMenu}>
              <>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  width="2rem"
                  heigh="2rem"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path>
                </svg>
              </>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
