import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "sonner";
import Success from "../assets/success.gif";

export const ArtInformation = () => {
  const [artworks, setArtworks] = useState(null);
  const [email, setEmail] = useState(null);
  const [user, setUser] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const fetchProduct = async () => {
      const res = await axios.get(
        `https://api.mintlogixart.com/api/user/art/${id}`
      );
      setArtworks(res.data[0]);
    };
    fetchProduct();
  }, [id]);

  const navigate = useNavigate();
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://api.mintlogixart.com/api/verify").then((res) => {
      if (res.data.valid) {
        setEmail(res.data.email);
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`https://api.mintlogixart.com/api/users/${email}`).then((res) => {
      setUser(res.data);
    });
  }, [email]);

  const handlePurchase = async (e) => {
    // console.log(!user);
    if (!user) {
      window.location.replace("/login");
      return;
    }
    if (artworks.price > user.balance || user.length < 0) {
      toast.error("Insufficent Balance: please fund your account");
    } else {
      const updatedBalance = user.balance - artworks.price;
      const updatedUser = { ...user, balance: updatedBalance };
      await axios.put(
        `https://api.mintlogixart.com/api/users/${email}`,
        updatedUser
      );

      const newArtData = {
        owner_id: user.id,
        username: user.users_name,
      };
      await axios.put(
        `https://api.mintlogixart.com/api/artwork/update/${id}`,
        newArtData
      );
      document.getElementById("successBtn").click();
      toast.success("Purchase successful");
    }
  };

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // get user

  return (
    <section className="page-width product-page">
      <Toaster />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Confirm order purchase
              </h5>
              <button
                type="button"
                id="purchaseClose"
                class="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style={{ color: "black" }}>
                Hi,{" "}
                <span style={{ color: "green", fontWeight: "800" }}>
                  {user?.users_name}
                </span>
                , You agree to pay{" "}
                <span style={{ color: "red", fontWeight: "800" }}>
                  {USDollar.format(artworks?.price)}
                </span>{" "}
                for this art, amount will be deducted from your profile balance:
                <span style={{ fontWeight: "800" }}>(${user?.balance})</span>
              </p>
            </div>
            <div class="modal-footer">
              <button
                onClick={handlePurchase}
                type="button"
                style={{ background: "green" }}
                class="btn btn-secondary"
              >
                Purchase
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* success message  */}
      <div class="row">
        <div class="col-md-4">
          <button
            hidden
            type="button"
            id="successBtn"
            class="btn btn-block bg-gradient-warning mb-3"
            data-bs-toggle="modal"
            data-bs-target="#modal-notification"
          >
            Purchase Successful!
          </button>
          <div
            class="modal fade"
            id="modal-notification"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-notification"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-danger modal-dialog-centered modal-"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title" id="modal-title-notification">
                    Congratulations 🎈🎉
                  </h6>
                  <button
                    type="button"
                    class="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="text-gradient text-danger mt-4">
                      Purchase completed!
                    </h4>
                    <img src={Success} />
                    <p style={{ color: "black" }}>
                      You can now view your purchased artwork on your profile.
                      <br /> Click on Artworks on the menu or{" "}
                      <a href="/dashboard">Here</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="product-image">
          <div class="card z-index-2">
            <div class="card-body p-3">
              <div class="border-radius-lg py-3 pe-1">
                <div class="chart">
                  <img
                    style={{ width: "100%" }}
                    src={`../uploads/${artworks?.image_url}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-details">
          <h2>{artworks?.name}</h2>
          <p>{artworks?.description}</p>
          <div className="owner-info">
            <p>
              <strong>PRICE:</strong> {USDollar.format(artworks?.price)}
            </p>
            <p>
              {" "}
              <strong>OWNER:</strong> {artworks?.owner}
            </p>
            <p>
              {" "}
              <strong>CREATED:</strong> {artworks?.created_at}
            </p>
          </div>

          <button
            type="button"
            class="btn-lg btn btn-block bg-gradient-warning mb-3"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            BUY ART NOW
          </button>
          {/* <button className="btn sign-up">View Details</button> */}
        </div>
      </div>
    </section>
  );
};
