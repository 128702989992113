import React from "react";
import Fheader from "../components/Fheader";
import FheaderIntro from "../components/Fheader-intro";
import FeaturedArts from "../components/FeaturedArts";
import FFooter from "../components/FFooter";

const Home = ({ user }) => {
  // console.log(user);
  return (
    <>
      <Fheader />
      <br />
      <br />
      <FheaderIntro />
      <br />
      <br />
      <FeaturedArts />
      <br />
      <br />
      <FFooter />
    </>
  );
};

export default Home;
