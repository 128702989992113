import React, { useEffect, useState } from "react";
import Header from "./Header";
import { toast, Toaster } from "sonner";
import ImageUploadThumbnail from "./ImageUploadThumbnail";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const FundUser = ({ user }) => {
  const [file, setFile] = useState(null);
  const [owner, setOwner] = useState(null);
  const [values, setValues] = useState({
    owner: "",
  });
  function copyItem() {
    var copyText = document.getElementById("eth");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast.success("Copied Address: " + copyText.value);
  }

  const navigate = useNavigate();
  useEffect(() => {
    setOwner(user.users_name);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Attach proof of payment");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("image", file);
      const res = await axios.post(
        "https://api.mintlogixart.com/upload",
        formData
      );
      const image_url = res.data;
      const date = moment(Date.now()).format("YYYY-MM-DD");
      const newData = {
        image_url,
        created_at: date,
        owner,
      };

      //   console.log(newData);
      try {
        axios
          .post("https://api.mintlogixart.com/api/transactions", newData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Please with for confirmation");
              setTimeout(() => {
                navigate("/dashboard");
              }, "3000");
            } else {
              toast.error("Error uploading Proof..");
            }
          });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <Header user={user} />
      <Toaster />
      <div className="container-fluid py-4">
        <div class="card h-100">
          <div class="card-body p-3">
            <div class="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <h3 class="mb-3">Fund your Account</h3>
                  <p style={{ color: "#030303" }}>
                    Easily fund your account by sending payment to the below
                    address
                  </p>
                  <p style={{ color: "#030303" }}>
                    Upload payment recipt using the file uploader for
                    confirmation, it usually takes less than 3 minutes to
                    confirm a payment
                  </p>
                  <div class="form-group">
                    <label
                      style={{ color: "black" }}
                      for="exampleFormControlSelect1"
                    >
                      Wallet Address
                    </label>
                    <input
                      disabled
                      type="text"
                      value="0xe7204a1a1f67B1748834eDBDbE178dbCbfD6320f"
                      class="form-control"
                      id="eth"
                    />
                    <button onClick={copyItem} id="copy" className="btn">
                      Copy
                    </button>

                    <button
                      onClick={handleSubmit}
                      className="btn bg-gradient-info w-100 mt-4 mb-0"
                    >
                      Upload proof
                    </button>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <p style={{ color: "#030303" }}>
                    Please attach a screenshot of payment reciept
                  </p>

                  <div className="thumb" style={{ textAlign: "center" }}>
                    {file ? (
                      <img
                        alt=""
                        src={URL.createObjectURL(file)}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        alt=""
                        src="https://global.discourse-cdn.com/brave/original/3X/7/b/7b3bb5254e3b09aca861f7578c0d71af22f50bc1.jpeg"
                        style={{ width: "200px" }}
                      />
                    )}
                  </div>
                  <br />
                  <input
                    type="file"
                    name="image_url"
                    id=""
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  {/* <button className="btn">Submit</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundUser;
