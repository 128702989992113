import React, { useEffect, useState } from "react";
import Header from "./Header";
import { toast, Toaster } from "sonner";
import ImageUploadThumbnail from "./ImageUploadThumbnail";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CreateArtwork = ({ user }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    setOwner(user.users_name);
    setOwnerId(user.id);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(user.balance);
    if (user.balance <= 0) {
      setLoading(false);
      return toast.warning("Please fund your account to create an Artwork");
    }
    if (!file) {
      setLoading(false);
      return toast.warning("Attach a valid Artwork image");
    }
    try {
      const formData = new FormData();
      formData.append("image", file);
      const res = await axios.post(
        "https://api.mintlogixart.com/upload",
        formData
      );
      const image_url = res.data;
      const date = moment(Date.now()).format("YYYY-MM-DD");
      const newData = {
        ...values,
        owner,
        image_url,
        created_at: date,
        owner_id: ownerId,
      };

      console.log(newData);
      try {
        axios
          .post("https://api.mintlogixart.com/create", newData)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              toast.success("Artwork Created Successfully");
              setTimeout(() => {
                navigate("/dashboard");
              }, "3000");
            } else {
              toast.error("Error uploading Artwork..");
            }
          });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <Header user={user} />

      <Toaster />
      <div className="container-fluid py-4">
        <div class="card h-100">
          <div class="card-body p-3">
            <div class="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <form>
                    <h6 class="mb-3">Create Your Artwork</h6>
                    <div class="form-group">
                      <label
                        style={{ color: "black" }}
                        for="exampleFormControlSelect1"
                      >
                        Artwork Name
                      </label>
                      <input
                        onChange={(e) =>
                          setValues({ ...values, name: e.target.value })
                        }
                        type="text"
                        placeholder="Lorem Ipsum"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      />
                    </div>
                    <div class="form-group">
                      <label
                        style={{ color: "black" }}
                        for="exampleFormControlSelect1"
                      >
                        Artwork Price
                      </label>
                      <input
                        onChange={(e) =>
                          setValues({ ...values, price: e.target.value })
                        }
                        type="text"
                        name="price"
                        placeholder="$5,000"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      />
                    </div>

                    <div class="form-group">
                      <label
                        style={{ color: "black" }}
                        for="exampleFormControlSelect1"
                      >
                        Artwork Owner
                      </label>

                      <input
                        disabled
                        name="owner"
                        type="text"
                        value={user?.users_name}
                        placeholder="Lorem Ipsum"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      />
                    </div>

                    <div class="form-group">
                      <label
                        style={{ color: "black" }}
                        for="exampleFormControlSelect1"
                      >
                        Artwork description
                      </label>
                      <textarea
                        name="description"
                        style={{ height: "200px" }}
                        onChange={(e) =>
                          setValues({ ...values, description: e.target.value })
                        }
                        type="text"
                        placeholder="Lorem Ipsum"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      ></textarea>
                    </div>

                    <div class="text-center">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        class="btn bg-gradient-info w-100 mt-4 mb-0"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                          ></span>
                        ) : (
                          ""
                        )}
                        Upload Art
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-md-6 col-sm-12">
                  <span>uploads needs to be high resolution</span>
                  <div className="thumb">
                    {file ? (
                      <img
                        src={URL.createObjectURL(file)}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <ImageUploadThumbnail />
                    )}
                  </div>
                  <br />
                  <input
                    type="file"
                    name="image_url"
                    id=""
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateArtwork;
