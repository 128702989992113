import axios from "axios";
import React, { useEffect, useState } from "react";

const FeaturedArts = () => {
  const [artworks, setArtworks] = useState(null);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://api.mintlogixart.com/api/artworks").then((res) => {
      setArtworks(res.data);
    });
  }, []);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <div className="page-width">
      <p className="blinkText">Featured Artworks</p>
      <div className="row">
        {artworks?.map((item) => (
          <div class="card col-4 z-index-2">
            <a href={`/art-details/${item.id}`}>
              <div class="card-body p-3">
                <div class="border-radius-lg py-3 pe-1">
                  <div class="chart">
                    <img
                      style={{ width: "100%" }}
                      src={`../uploads/${item.image_url}`}
                      alt={item.name}
                    />
                  </div>
                </div>
                <h6 class="mb-0"> {item.name}</h6>
                <h6 class="mb-0">{USDollar.format(item.price)}</h6>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedArts;
