import React from "react";

const FFooter = () => {
  return (
    <div className="page-width" style={{ textAlign: "center" }}>
      © 2024 mintlogixart.com
    </div>
  );
};

export default FFooter;
