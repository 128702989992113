import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Register() {
  const [values, setValues] = useState({
    email: "",
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    if (!values.email || !values.password || !values.username) {
      return toast.error("Fill in all the forms");
    }
    axios.defaults.withCredentials = true;
    axios
      .post("https://api.mintlogixart.com/api/register", values)
      .then((res) => {
        if (res.status === 200) {
          navigate("/login");
        } else {
          toast.error(res.data.message);
        }
      });
  };

  return (
    <div className="App">
      <ToastContainer />
      <main class="main-content  mt-0">
        <section>
          <div class="page-header min-vh-75">
            <div class="container">
              <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                  <div class="card card-plain mt-6">
                    <div class="card-header pb-0 text-left bg-transparent">
                      <h3 class="font-weight-bolder text-info text-gradient">
                        Create an Account
                      </h3>
                      <p class="mb-0">
                        Please sign up with a valid email and password
                      </p>
                    </div>
                    <div class="card-body">
                      <form role="form" onSubmit={handleSignUp}>
                        <label>Username</label>
                        <div class="mb-3">
                          <input
                            onChange={(e) =>
                              setValues({ ...values, username: e.target.value })
                            }
                            type="text"
                            class="form-control"
                            placeholder="User Name"
                            aria-label="Username"
                            aria-describedby="user-addon"
                          />
                        </div>
                        <label>Email</label>
                        <div class="mb-3">
                          <input
                            onChange={(e) =>
                              setValues({ ...values, email: e.target.value })
                            }
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="email-addon"
                          />
                        </div>
                        <label>Password</label>
                        <div class="mb-3">
                          <input
                            onChange={(e) =>
                              setValues({ ...values, password: e.target.value })
                            }
                            type="password"
                            class="form-control"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                          />
                        </div>

                        <div class="text-center">
                          <button
                            type="submit"
                            class="btn bg-gradient-info w-100 mt-4 mb-0"
                          >
                            Sign up
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="card-footer text-center pt-0 px-lg-2 px-1">
                      <p class="mb-4 text-sm mx-auto">
                        Already have an account? &nbsp;
                        <a
                          href="/login"
                          class="text-info text-gradient font-weight-bold"
                        >
                          Sign in
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                    <div
                      class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style={{
                        backgroundImage:
                          "url('../assets/img/curved-images/curved6.jpg')",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Register;
