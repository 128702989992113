import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import EditUserSection from "../components/EditUserSection";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const EditUser = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState([]);
  const [editableUser, setEditableUser] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://api.mintlogixart.com/api/verify").then((res) => {
      if (res.data.valid === true) {
        setEmail(res.data.email);
      } else {
        navigate("/login");
      }
    });

    axios.get(`https://api.mintlogixart.com/api/user/${id.id}`).then((res) => {
      setEditableUser(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`https://api.mintlogixart.com/api/users/${email}`).then((res) => {
      setUser(res.data);
    });
  }, [email]);
  return (
    <>
      <Sidebar user={user} active={"users"} />
      <EditUserSection user={editableUser} activeUser={user} />
    </>
  );
};

export default EditUser;
